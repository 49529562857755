import { Service } from '../services';
import { Maintenance } from './MaintenanceSlice';


export class MaintenanceForm {
    id?: number;
    service?: Service;
    title?: string;
    description?: string;
    plannedFrom?: Date;
    plannedUntil?: Date;
    reportedBy: string | undefined;
    reportedAt: Date | undefined;
    updatedBy: string | undefined;
    updatedAt: Date | undefined;
    notifyUsers: boolean = false;

    static from(maintenance: Maintenance): MaintenanceForm {
        return {
            id: maintenance.id,
            service: maintenance.service,
            title: maintenance.title,
            description: maintenance.description,
            plannedFrom: toOptionalDate(maintenance.plannedFrom),
            plannedUntil: toOptionalDate(maintenance.plannedUntil),
            reportedBy: maintenance.reportedBy,
            reportedAt: toOptionalDate(maintenance.reportedAt),
            updatedBy: maintenance.updatedBy,
            updatedAt: toOptionalDate(maintenance.updatedAt),
            notifyUsers: false
        };
    }

    static isValid(maintenanceForm: MaintenanceForm | undefined): boolean {
        if (maintenanceForm === undefined) {
            return false;
        }

        function containsValue(value: any) {
            return value !== undefined && value !== null && (value.length === undefined || value.length > 0);
        }

        if (!containsValue(maintenanceForm.service)) {
            return false;
        }

        if (!containsValue(maintenanceForm.title)) {

            return false;
        }
        if (!containsValue(maintenanceForm.description)) {
            return false;
        }

        if (!containsValue(maintenanceForm.plannedFrom)) {
            return false;
        }

        return true;
    }

}
function toOptionalDate(date: Date | undefined) {
    return (date) ? new Date(date) : undefined;
}

