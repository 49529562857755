import {createSlice} from '@reduxjs/toolkit';
import {defaultGenericState, GenericState} from '../model';
import {getServiceProfile, getServiceProfiles, saveServiceProfile} from './ServiceProfileApi';
import {addGenericStateListBuilders, addGenericStateSingleBuilders} from '../slice';

export interface ServiceProfile {
    externalKey: string | undefined;
    name: string;
}

export interface ServiceProfileState extends GenericState<ServiceProfile> {
}

export const serviceProfileSlice = createSlice({
    name: 'ServiceProfiles',
    initialState: defaultGenericState,
    reducers: {},
    extraReducers: builder => {
        addGenericStateListBuilders(builder, getServiceProfiles);
        addGenericStateSingleBuilders(builder, getServiceProfile);
        addGenericStateSingleBuilders(builder, saveServiceProfile);
    },
});

