// third-party
import {FormattedMessage} from 'react-intl';

// assets
import {HomeTrendUp, TicketStar} from 'iconsax-react';

// type
import {NavItemType} from '../../../atoms/types/menu';
import React from 'react';

const dashboard: NavItemType = {
  id: 'group-dashboard',
  title:  <FormattedMessage id="menu.monitor" />,
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: <FormattedMessage id="menu.dashboard" />,
      type: 'item',
      url: '/dashboard/default',
      icon: HomeTrendUp,
      breadcrumbs: true
    },
    {
      id: 'issues',
      title: <FormattedMessage id="menu.issues" />,
      type: 'item',
      url: '/dashboard/issues',
      icon: TicketStar,
      breadcrumbs: true
    }
  ]
};

export default dashboard;
