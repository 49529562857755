import { createAsyncThunk } from '@reduxjs/toolkit';
import { setLoading } from '../global';
import axios from 'axios';
import { CachedAuthState } from './AuthSlice';

export const getAuthCachedState = createAsyncThunk(
    'auth/getCache', ({}: {}, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        let authStateFromStorage = localStorage.getItem('authState');
        const authState = (authStateFromStorage !== null) ? JSON.parse(authStateFromStorage) : null;
        thunkAPI.dispatch(setLoading(false));
        return Promise.resolve(authState);
    },
);

export const logout = createAsyncThunk(
    'auth/logout', async ({}: {}, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        localStorage.setItem('authState', '');
        localStorage.removeItem('authState');
        thunkAPI.dispatch(setLoading(false));
    },
);

export const authPrepare = createAsyncThunk(
    'auth/prepare', ({emailAddress}: { emailAddress: string }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        var body = {'emailAddress': emailAddress};
        return axios.post('/authentication/prepare', body)
            .then(value => {
                const authState = {
                    emailAddress: emailAddress
                } as CachedAuthState
                localStorage.setItem('authState', JSON.stringify(authState));
                return emailAddress;
            })
            .catch(reason => {
                return Promise.reject(reason);
            })
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);

export const authAuthenticate = createAsyncThunk(
    'auth/authenticate', ({emailAddress, authenticationCode}: { emailAddress: string, authenticationCode: string }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));

        var body = {
            'emailAddress': emailAddress,
            'authenticationCode': authenticationCode,
            'authSource': 'WEB_ADMIN'
        };
        return axios.post('/authentication/authenticate', body)
            .then(response => {
                const authState = {
                    emailAddress: emailAddress,
                    jwtToken: response.data.jwtToken,
                    roles: response.data.roles
                } as CachedAuthState
                localStorage.setItem('authState', JSON.stringify(authState));
                return response.data;
            })
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);


export function bearerAuth(token: string | undefined) {
    return `Bearer ${token}`
}
