import React from 'react';
import { IconProps } from "./index";
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';


export const IconResults: React.FC<IconProps> = ({color, size}) => {
    return (
        <BarChartOutlinedIcon sx={{width:{size}, height:{size}, color:{color}}}/>
    )
};
