import {createSlice} from '@reduxjs/toolkit';
import {defaultGenericPagableState, GenericPageableState} from '../model';
import {addGenericPageableStateListBuilders, addGenericPageableStateSingleBuilders} from '../slice';
import {getIssueType, getIssueTypes, saveIssueType, saveIssueTypeDisplayOrder} from "./IssueTypeApi";


export interface IssueType {
    id: number;
    description: string;
    logo: string;
    displayOrder: number;
}

export interface IssueTypeState extends GenericPageableState<IssueType> {
}

export const issueTypeSlice = createSlice({
    name: 'IssueTypes',
    initialState: defaultGenericPagableState,
    reducers: {},
    extraReducers: builder => {
        addGenericPageableStateListBuilders(builder, getIssueTypes);
        addGenericPageableStateListBuilders(builder, saveIssueTypeDisplayOrder);
        addGenericPageableStateSingleBuilders(builder, getIssueType);
        addGenericPageableStateSingleBuilders(builder, saveIssueType);
    },
});

