import {ServiceProfile} from '../serviceprofiles';

export class ServiceProfileForm {
    externalKey?: string;
    name: string = '';

    static from(serviceProfile: ServiceProfile): ServiceProfileForm {
        return {
            externalKey: serviceProfile.externalKey,
            name: serviceProfile.name
        };
    }

    static isValid(serviceProfileForm: ServiceProfileForm | undefined): boolean {
        if (serviceProfileForm === undefined) {
            return false;
        }

        function containsValue(value: any) {
            return value !== undefined && value !== null && (value.length === undefined || value.length > 0);
        }

        if (!containsValue(serviceProfileForm.name)) {
            return false;
        }

        return true;
    }

}
