import React, { ReactNode } from 'react';
import styled from 'styled-components';


interface Props {
  children: ReactNode;
}

const MuiStyle = styled.div`
  max-width: 200px;
  padding: 14px;
  border: solid 1px #BEC8D0;
  border-radius: 8px;

  &:focus-within {
    border: solid 1px #38b09c;
  }

  & input {
    font-family: 'Inter var';
  }
`;
export const MuiBox: React.FC<Props> = (props) => {
  return <MuiStyle>{props.children}</MuiStyle>;
};
