// material-ui
import {useTheme} from '@mui/material/styles';
import {Stack, useMediaQuery} from '@mui/material';

// project-imports
import DrawerHeaderStyled from './DrawerHeaderStyled';

import Logo from '../../../../atoms/logo';

import useConfig from '../../../../../hooks/useConfig';

// types
import React from 'react';
import {LAYOUT_CONST} from '../../../../atoms/types/config';
import {DRAWER_WIDTH, HEADER_HEIGHT} from '../../../../../config';

// ==============================|| DRAWER HEADER ||============================== //

interface Props {
  open: boolean;
}

const DrawerHeader = ({ open }: Props) => {
  const theme = useTheme();
  const downLG = useMediaQuery(theme.breakpoints.down('lg'));

  const { menuOrientation } = useConfig();
  const isHorizontal = menuOrientation === LAYOUT_CONST.HORIZONTAL_LAYOUT && !downLG;

  return (
    <DrawerHeaderStyled
      theme={theme}
      open={open}
      sx={{
        minHeight: isHorizontal ? 'unset' : HEADER_HEIGHT,
        width: isHorizontal ? { xs: '100%', lg: DRAWER_WIDTH + 50 } : 'inherit',
        paddingTop: isHorizontal ? { xs: '10px', lg: '0' } : '8px',
        paddingBottom: isHorizontal ? { xs: '18px', lg: '0' } : '8px',
        paddingLeft: isHorizontal ? { xs: '24px', lg: '0' } : open ? '24px' : 0
      }}
    >
        <Stack direction="row" spacing={2}>
            <Logo isIcon={!open} sx={{ width: open ? 150 : 52, height: 'auto' }} />
            {/*<Stack direction="column" sx={{ pt: 1 }}>*/}
            {/*{open && <Chip label="Premium" variant="light" color="success" size="small"/> }*/}
            {/*</Stack>*/}
        </Stack>
    </DrawerHeaderStyled>
  );
};

export default DrawerHeader;
