import {createAsyncThunk} from '@reduxjs/toolkit';
import {setLoading} from '../global';
import axios from 'axios';
import {bearerAuth} from '../auth';
import {BackendSortParams, OrderEnum} from '../../../components';
import {IssueTypeForm} from "./IssueTypeForm";

export const getIssueTypes = createAsyncThunk(
    'IssueTypes/getIssueTypes', (params: {
        jwtToken: string,
        page: number,
        pageSize: number,
        sortParameters: BackendSortParams
    }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(params.jwtToken)}
        };
        var url = '/manage/issueTypes?page=' + params.page + '&size=' + params.pageSize + "&sort=" + params.sortParameters.field + "," + OrderEnum[params.sortParameters.order];

        return axios.get(url, config)
            .then(response => response.data)
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);

export const saveIssueType = createAsyncThunk(
    'IssueTypes/saveIssueType', (params: {
        jwtToken: string,
        issueTypeForm: IssueTypeForm
    }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(params.jwtToken)}
        };

        return axios.post('/manage/issueTypes', params.issueTypeForm, config)
            .then(response => response.data)
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);

export const getIssueType = createAsyncThunk(
    'IssueTypes/getIssueType', (params: {
        jwtToken: string,
        issueTypeId: number
    }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(params.jwtToken)}
        };
        var url = '/manage/issueTypes/' + params.issueTypeId;

        return axios.get(url, config)
            .then(response => response.data)
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);

export const saveIssueTypeDisplayOrder = createAsyncThunk(
    'IssueTypes/saveIssueTypeDisplayOrder', (params: {
        jwtToken: string,
        issueTypeId: number,
        newDisplayOrder: number,
        page: number,
        pageSize: number,
        sortParameters: BackendSortParams
    }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(params.jwtToken)}
        };
        var url = '/manage/issueTypes/' + params.issueTypeId + '/' + params.newDisplayOrder + '?page=' + params.page + '&size=' + params.pageSize + "&sort=" + params.sortParameters.field + "," + OrderEnum[params.sortParameters.order];
        return axios.post(url, {}, config)
            .then(response => response.data)
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);

