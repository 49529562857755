import React, { FC } from 'react';
import styled from 'styled-components';
import { t } from 'i18next';

interface Props {
    errorMessage: string | undefined | null
    errorCode?: string | undefined
    show: boolean
}

export const ErrorAlert: FC<Props> = (props) => {
    let messageToShow = ((props.errorCode === undefined) || (props.errorCode === '')) ? props.errorMessage : t('error.errorCodes.' + props.errorCode);
    if (messageToShow && props.show) {
        return (<Error>{messageToShow}</Error>);
    } else {
        return (<span/>);
    }
};

const Error = styled.div` {
    background-color: red;
    padding: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: white;
}`
