// material-ui
import { Box } from '@mui/material';

// project-imports
import React from 'react';
import Navigation from './Navigation';
import SimpleBarScroll from '../../../atoms/SimpleBar';

// ==============================|| DRWAER - CONTENT ||============================== //

const DrawerContent = ({ searchValue }: { searchValue?: string }) => (
  <SimpleBarScroll
    sx={{
      height: { xs: 'calc(100vh - 70px)', md: 'calc(100% - 70px)' },
      '& .simplebar-content': {
        display: 'flex',
        flexDirection: 'column'
      }
    }}
  >
    <Box sx={{ p: 3, pt: 1.5 }}>
      <Navigation searchValue={searchValue} />
    </Box>
  </SimpleBarScroll>
);

export default DrawerContent;
