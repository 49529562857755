export const eclipsText = (text: string, maxLength: number) => {
    if (text && text.length > maxLength) {
        return text.slice(0, maxLength - 3) + "...";
    }
    return text;
}


export function toDistinctCommaSeperatedList<T>(items: T[] | undefined, propertyGetter: (item: T) => string): string {
    if (items) {
        return items
            .map(value => propertyGetter(value))
            .filter((value, index, self) => self.indexOf(value) === index) // distinct
            .join(", ");
    }
    return "";
}
