import React from 'react';
import {IconProps} from "./index";


export const IconExcel: React.FC<IconProps> = ({color, size}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16">
            <g transform="translate(-71.09-24.1)">
                <path
                    d="m24.321 1013.69l-7.918 1.397v10.247l7.918 1.397zm.466.932v.932h1.863v1.397h-1.863v.466h1.863v1.397h-1.863v.466h1.863v1.397h-1.863v.466h1.863v1.397h-1.863v.466h1.863v1.397h-1.863v.932h5.589v-10.713zm2.329.932h2.329v1.397h-2.329zm0 1.863h2.329v1.397h-2.329zm-5.284.335c.085 0 .131-.001.131 0 0 .001-.238.548-.539 1.193l-.553 1.164.553 1.164c.3.636.539 1.181.539 1.208 0 .056-.401.046-.742-.015-.226-.04-.237-.066-.524-.786-.164-.41-.322-.772-.349-.801-.028-.029-.187.282-.349.684-.162.402-.313.747-.335.771-.022.024-.221.024-.451 0l-.422-.044.509-1.077.509-1.077-.451-1.033c-.247-.57-.451-1.08-.451-1.121 0-.045.171-.073.437-.073h.437l.247.699c.139.383.28.714.306.742.026.028.199-.312.378-.757l.32-.801.466-.029c.128-.001.25-.013.335-.015zm5.284 1.528h2.329v1.397h-2.329zm0 1.863h2.329v1.397h-2.329zm0 1.863h2.329v1.397h-2.329z"
                    stroke="none" color="#fff" transform="matrix(.92014 0 0 .92014 57.57-906.63)" fill="#ffffff"/>
            </g>
        </svg>
    );
};
