import { createSlice } from '@reduxjs/toolkit';
import { getDisruption, getDisruptions, saveDisruption } from './DisruptionsApi';
import { Service } from '../services';
import { defaultGenericPagableState, GenericPageableState } from '../model';
import { addGenericPageableStateListBuilders, addGenericPageableStateSingleBuilders } from '../slice';


export interface Disruption {
    id: number;
    service: Service;
    description: string;
    dominantIssueTypeId: number;
    dominantIssueTypeDescription: string;
    dominantImpactTypeId: number;
    dominantImpactTypeDescription: string;
    startedAt: Date;
    endedAt: Date;
    issueCount: number;

}

export interface DisruptionsState extends GenericPageableState<Disruption> {}


export const disruptionSlice = createSlice({
    name: 'disruptions',
    initialState: defaultGenericPagableState,
    reducers: {},
    extraReducers: builder => {
        addGenericPageableStateListBuilders(builder,getDisruptions);
        addGenericPageableStateSingleBuilders(builder, getDisruption);
        addGenericPageableStateSingleBuilders(builder, saveDisruption);
    },
});

