import axios from 'axios'
import { createAsyncThunk } from "@reduxjs/toolkit";
import { setLoading } from "../global";

export const getUserExtrasMe = createAsyncThunk(
    "authentication/getUserExtrasMe", (token: string, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true))
        return axios.get('/api/authentication/me', {
                headers: { 'Authorization': bearerAuth(token) }
            })
            .then(response => response.data)
            .finally(() => {
                thunkAPI.dispatch(setLoading(false))
            });
    }
);

function bearerAuth(token: String) {
    return `Bearer ${token}`
}
