import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {authAuthenticate, authPrepare, getAuthCachedState, logout} from './AuthApi';
import {getErrorCodeFromMessage} from "../slice";

export interface CachedAuthState {
    emailAddress: string | undefined;
    jwtToken: string | undefined;
    roles: string[] | undefined;
}

export interface AuthState {
    loadExecuted: boolean;
    loading: boolean;
    error: string | undefined;
    errorCode: string | undefined;
    emailAddress: string | undefined;
    jwtToken: string | undefined;
    jwtTokenExpired: boolean;
    authenticated: boolean;
    roles: string[] | undefined;
    isTenantAdmin: boolean | undefined;
}

const INITIAL_STATE: AuthState = {
    loadExecuted: false,
    loading: false,
    error: undefined,
    errorCode: undefined,
    emailAddress: undefined,
    jwtToken: undefined,
    jwtTokenExpired: false,
    authenticated: false,
    isTenantAdmin: false,
    roles: []
};

function isTenantAdmin(roles: string[] | undefined) {
    if (roles) {
        return roles.includes("TENANT_ADMIN");
    }
    return false;
}

export const authSlice = createSlice({
    name: 'auth',
    initialState: INITIAL_STATE,
    reducers: {},
    extraReducers: builder => {
        builder.addCase('jwtExpired', (state: AuthState, action) => {
            return {
                ...state,
                jwtTokenExpired: true
            };
        });
        builder.addCase(authPrepare.pending.type, (state: AuthState, action) => {
            return {
                ...state,
                loading: true,
                error: undefined,
            };
        });
        builder.addCase(authPrepare.fulfilled.type, (state: AuthState, action: any) => {
            return {
                ...state,
                loadExecuted: true,
                loading: false,
                error: undefined,
                emailAddress: action.payload
            };
        });
        builder.addCase(authPrepare.rejected.type, (state: AuthState, action: any) => {
            return {
                ...state,
                loadExecuted: true,
                loading: false,
                error: action.error.message,
                errorCode: getErrorCodeFromMessage(action.error.message),
            };
        });
        builder.addCase(authAuthenticate.pending.type, (state: AuthState, action) => {
            return {
                ...state,
                loading: true,
                error: undefined,
            };
        });
        builder.addCase(authAuthenticate.fulfilled.type, (state: AuthState, action: any) => {
            return {
                ...state,
                loadExecuted: true,
                loading: false,
                error: undefined,
                jwtToken: action.payload.jwtToken,
                jwtTokenExpired: false,
                roles: action.payload.roles,
                isTenantAdmin: isTenantAdmin(action.payload.roles),
                authenticated: ((action.payload.jwtToken !== undefined) && (action.payload?.jwtToken?.length > 10)),
            };
        });
        builder.addCase(authAuthenticate.rejected.type, (state: AuthState, action: any) => {
            return {
                ...state,
                loadExecuted: true,
                loading: false,
                error: action.error.message,
                jwtToken: undefined,
                isTenantAdmin: false,
                roles: [],
                errorCode: getErrorCodeFromMessage(action.error.message),
            };
        });
        builder.addCase(getAuthCachedState.pending.type, (state: AuthState, action) => {
            return {
                ...state,
                loading: true,
                error: undefined,
            };
        });
        builder.addCase(getAuthCachedState.fulfilled.type, (state: AuthState, action: PayloadAction<CachedAuthState>) => {
            return {
                ...state,
                loadExecuted: true,
                loading: false,
                error: undefined,
                jwtToken: action.payload?.jwtToken,
                roles: action.payload?.roles,
                isTenantAdmin: isTenantAdmin(action.payload?.roles),
                jwtTokenExpired: false,
                emailAddress: action.payload?.emailAddress,
                authenticated: ((action.payload !== undefined) && (action.payload?.jwtToken !== undefined) && (action.payload.jwtToken.length > 10)),
            };
        });
        builder.addCase(getAuthCachedState.rejected.type, (state: AuthState, action: any) => {
            return {
                ...state,
                loadExecuted: true,
                loading: false,
                error: action.error.message,
                errorCode: getErrorCodeFromMessage(action.error.message),
            };
        });
        builder.addCase(logout.pending.type, (state: AuthState, action) => {
            return {
                ...state,
                loading: true,
                error: undefined
            };
        });
        builder.addCase(logout.fulfilled.type, (state: AuthState, action) => {
            return {
                ...state,
                loadExecuted: true,
                loading: false,
                error: undefined,
                jwtToken: undefined,
                isTenantAdmin: false,
                jwtTokenExpired: false,
                authenticated: false,
            };
        });
        builder.addCase(logout.rejected.type, (state: AuthState, action: any) => {
            return {
                ...state,
                loadExecuted: true,
                loading: false,
                error: action.error.message,
                errorCode: getErrorCodeFromMessage(action.error.message),
                isTenantAdmin: false
            };
        });
    },
});


