// material-ui
import {Button, CardMedia, Link, Stack, Typography} from '@mui/material';

// project-imports
import MainCard from '../../../../molecules/MainCard';

// assets
import avatar from '../../../../../assets/images/users/customer-support-1.png';
import AnimateButton from '../../../../atoms/AnimateButton';
import React from 'react';

// ==============================|| DRAWER CONTENT - NAV CARD ||============================== //

const NavCard = () => (
  <MainCard sx={{ bgcolor: 'secondary.lighter', m: 3 }}>
    <Stack alignItems="center" spacing={2.5}>
      <CardMedia component="img" image={avatar} />
      <Stack alignItems="center">
        <Typography variant="h5">Ondersteuning nodig?</Typography>
        <Typography variant="h6" color="secondary">
          Reactie binnen 1 werkdag!
        </Typography>
      </Stack>
      <AnimateButton>
        <Button variant="shadow" size="small" component={Link} href="https://www.credenco.com#comp-lcc2nrsj" target="_blank">
          Vraag ondersteuning
        </Button>
      </AnimateButton>
    </Stack>
  </MainCard>
);

export default NavCard;
