import { createAsyncThunk } from '@reduxjs/toolkit';
import { setLoading } from '../global';
import axios from 'axios';
import { bearerAuth } from '../auth';

export const getIssueCounts = createAsyncThunk(
    'dashboard/getIssueCounts', ({jwtToken, serviceExternalKey, groupBy}: {jwtToken: string | undefined , serviceExternalKey: string, groupBy: string}, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };

        return axios.get('/app/issue?serviceExternalKey=' + serviceExternalKey + '&groupBy=' + groupBy, config)
            .then(response => {
                return response.data
            })
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);

export const getDashboardInfo = createAsyncThunk(
    'dashboard/info', ({jwtToken}: {jwtToken: string | undefined}, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };

        return axios.get('/dashboard/info', config)
            .then(response => {
                return response.data
            })
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);
