import React from 'react';

interface Props {
  text: string | undefined
}

export const MailLink: React.FC<Props> = (props) => {
  if (props.text && props.text.includes("@")) {
    return (<a href={'mailto:' + props.text}>{props.text}</a> )
  } else {
    return (<span>{props.text}</span>)
  }
};
