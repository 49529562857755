import {IssueType} from "./IssueTypeSlice";

export class IssueTypeForm {
    id?: number;
    description: string = '';
    logo?: string;
    displayOrder?: number = 100;

    static from(issueType: IssueType): IssueTypeForm {
        return {
            id: issueType.id,
            description: issueType.description,
            logo: issueType.logo,
            displayOrder: issueType.displayOrder
        };
    }

    static isValid(form: IssueTypeForm | undefined): boolean {
        if (IssueTypeForm === undefined) {
            return false;
        }

        function containsValue(value: any) {
            return value !== undefined && value !== null && (value.length === undefined || value.length > 0);
        }

        if (!containsValue(form?.description)) {
            return false;
        }
        if (!containsValue(form?.logo)) {
            return false;
        }

        return true;
    }

}
