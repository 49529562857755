import {createAsyncThunk} from '@reduxjs/toolkit';
import {setLoading} from '../global';
import axios from 'axios';
import {bearerAuth} from '../auth';
import {ServiceForm} from '../services';

export const getServices = createAsyncThunk(
    'services/getServices', ({jwtToken}: {jwtToken: string | undefined }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };

        return axios.get('/manage/services?page=0&size=50', config)
            .then(response => response.data)
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);

export const getService = createAsyncThunk(
    'services/getService', ({jwtToken, serviceExternalKey}: { jwtToken: string, serviceExternalKey: string }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };

        return axios.get('/manage/services/' + serviceExternalKey, config)
            .then(response => response.data)
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);

export const saveService = createAsyncThunk(
    'services/saveService', ({jwtToken, serviceForm}: {jwtToken: string, serviceForm: ServiceForm}, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };
        const body = {
            externalKey: serviceForm.externalKey,
            name: serviceForm.name,
            reportingBucketSize: serviceForm.reportingBucketSize,
            reportingBucketPeriodInMinutes: serviceForm.reportingBucketPeriodInMinutes,
            reportingBucketMarginInMinutes: serviceForm.reportingBucketMarginInMinutes,
            administratorEmailAddresses: serviceForm.administratorEmailAddresses,
            additionalUserEmailAddresses: serviceForm.additionalUserEmailAddresses,
            base64EncodedLogo: serviceForm.base64EncodedLogo,
            location: serviceForm.location,
            allowedIssueTypes: serviceForm.allowedIssueTypes,
            allowedImpactTypes: serviceForm.allowedImpactTypes,
            serviceProfiles: serviceForm.serviceProfiles,
            showInApp: serviceForm.showInApp
        };

        return axios.post('/manage/services', body, config)
            .then(response => response.data)
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);
