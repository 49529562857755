import {ImpactType} from '../impacttypes';

export class ImpactTypeForm {
    id?: number;
    description: string = '';
    logo?: string;
    displayOrder?: number = 100;

    static from(impactType: ImpactType): ImpactTypeForm {
        return {
            id: impactType.id,
            description: impactType.description,
            logo: impactType.logo,
            displayOrder: impactType.displayOrder
        };
    }

    static isValid(form: ImpactTypeForm | undefined): boolean {
        if (ImpactTypeForm === undefined) {
            return false;
        }

        function containsValue(value: any) {
            return value !== undefined && value !== null && (value.length === undefined || value.length > 0);
        }

        if (!containsValue(form?.description)) {
            return false;
        }
        if (!containsValue(form?.logo)) {
            return false;
        }

        return true;
    }

}
