import {Service} from '../services';
import {Disruption} from './DisruptionsSlice';

export class DisruptionForm {
    id?: number;
    service?: Service;
    description?: string;
    dominantIssueTypeId?: number;
    dominantImpactTypeId?: number;
    startedAt?: Date;
    endedAt?: Date;
    notifyUsers: boolean = false;

    static from(disruption: Disruption): DisruptionForm {
        return {
            id: disruption.id,
            service: disruption.service,
            description: disruption.description,
            dominantIssueTypeId: disruption.dominantIssueTypeId,
            dominantImpactTypeId: disruption.dominantImpactTypeId,
            startedAt: new Date(disruption.startedAt),
            endedAt: (disruption.endedAt) ? new Date(disruption.endedAt) : undefined,
            notifyUsers: false
        };
    }

    static isValid(disruptionForm: DisruptionForm | undefined): boolean {
        if (disruptionForm === undefined) {
            return false;
        }

        function containsValue(value: any) {
            return value !== undefined && value !== null && (value.length === undefined || value.length > 0);
        }

        if (!containsValue(disruptionForm.service)) {
            return false;
        }

        if (!containsValue(disruptionForm.dominantIssueTypeId)) {
            return false;
        }

        if (!containsValue(disruptionForm.dominantImpactTypeId)) {
            return false;
        }
        if (!containsValue(disruptionForm.startedAt)) {
            return false;
        }

        return true;
    }
}
