import React, {FC, useState} from 'react';
import {authSelector, useAppDispatch} from "../../../state";
import {useSelector} from 'react-redux';
import {Container} from 'react-bootstrap';
import {feedbackSelector, sendInvitations} from '../../../state/slices/feedback';
import {ErrorAlert} from '../../molecules';
import {t} from 'i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {Button, FormControl, FormControlLabel, Grid, InputLabel, Radio, RadioGroup, Stack} from '@mui/material';
import MainCard from '../../molecules/MainCard';
import {enqueueSnackbar} from 'notistack';

interface Props {
}

export const FeedbackInviteUsersContent: FC<Props> = () => {
    const {feedbackId, serviceExternalKey } = useParams();
    const [feedbackInvitationType, setFeedbackInvitationType] = useState<string>('');
    const feedbackState = useSelector(feedbackSelector);
    let authState = useSelector(authSelector);
    let navigate = useNavigate();
    const dispatch = useAppDispatch();


    function isFormValid() {
        return (feedbackInvitationType !== '');
    }

    function sendInvitation() {
        if (feedbackId) {
            dispatch(sendInvitations({feedbackId: feedbackId, serviceExternalKey: serviceExternalKey, feedbackInvitationType: feedbackInvitationType, jwtToken: authState.jwtToken}))
                .then((response) => {
                    if (response.type.includes('fulfilled')) {
                        enqueueSnackbar(t('feedback.invite.usersInvitedMessage'), { variant: 'success' });
                        setTimeout(() => { // Use timeout the give time to update the redux store.
                            navigate(-1)
                        }, 250);
                    }
                });
        }
    }

    return (
        <Container>
            <ErrorAlert
                errorMessage={t('error.retrievingData')}
                show={feedbackState.error !== undefined}/>
            <MainCard title={t('feedback.invite.title')}>
                <Grid item xs={12} md={8}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <InputLabel htmlFor="description">{t('feedback.invite.intro')}</InputLabel>
                                <FormControl>
                                    <RadioGroup
                                        value={feedbackInvitationType}
                                        onChange={(e) => setFeedbackInvitationType(e.target.value)}
                                        name="radio-buttons-group">
                                        <FormControlLabel value="ONLY_NEW_USERS" control={<Radio/>}
                                                          label={t('feedback.invite.onlyNewUsers')}/>
                                        <FormControlLabel value="ONLY_USERS_WITHOUT_COMPLETED_FEEDBACK"
                                                          control={<Radio/>}
                                                          label={t('feedback.invite.onlyUsersWithoutCompletedFeedback')}/>
                                        <FormControlLabel value="ALL_USERS" control={<Radio/>}
                                                          label={t('feedback.invite.allUsers')}/>
                                    </RadioGroup>
                                </FormControl>
                            </Stack>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                            <Button variant="outlined" color="secondary"
                                    onClick={() => navigate(-1)}>{t('generic.back')}</Button>
                            <Button variant="contained" onClick={sendInvitation}
                                    disabled={feedbackState.loading || !isFormValid()}>{t('generic.save')}</Button>
                        </Stack>
                    </Grid>
                </Grid>
            </MainCard>
        </Container>
    );
}


