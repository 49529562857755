import {createAsyncThunk} from '@reduxjs/toolkit';
import {setLoading} from '../global';
import axios from 'axios';
import {bearerAuth} from '../auth';
import {ServiceProfileForm} from "./ServiceProfileForm";

export const getServiceProfiles = createAsyncThunk(
    'ServiceProfiles/getServiceProfiles', (params: { jwtToken: string | undefined }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(params.jwtToken)}
        };
        var url = '/manage/serviceProfiles';

        return axios.get(url, config)
            .then(response => response.data)
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);

export const getServiceProfile = createAsyncThunk(
    'ServiceProfiles/getServiceProfile', (params: {
        jwtToken: string | undefined,
        serviceProfileExternalKey: string
    }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(params.jwtToken)}
        };
        var url = '/manage/serviceProfiles/' + params.serviceProfileExternalKey;

        return axios.get(url, config)
            .then(response => response.data)
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);

export const saveServiceProfile = createAsyncThunk(
    'serviceProfiles/save', (params: {
        serviceProfileForm: ServiceProfileForm,
        jwtToken: string | undefined
    }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(params.jwtToken)}
        };

        let url = '/manage/serviceProfiles';
        return axios.post(url, params.serviceProfileForm, config)
            .then(response => response.data)
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);

