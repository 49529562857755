import {createAsyncThunk} from '@reduxjs/toolkit';
import {setLoading} from '../global';
import axios, {AxiosRequestConfig} from 'axios';
import {bearerAuth} from '../auth';
import {FeedbackAnswer} from './FeedbackSlice';
import {FeedbackForm} from './FeedbackForm';

export const getFeedbackRequests = createAsyncThunk(
    'feedback/getFeedbackRequests', ({jwtToken}: { jwtToken: string | undefined }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };

        return axios.get('/manage/feedback', config)
            .then(response => response.data)
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);

export const getFeedback = createAsyncThunk(
    'feedback/getFeedback', ({feedbackId, jwtToken}: {
        feedbackId: string | undefined,
        jwtToken: string | undefined
    }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };

        return axios.get('/manage/feedback/' + feedbackId, config)
            .then(response => response.data)
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);

export const getFeedbackByKey = createAsyncThunk(
    'feedback/getFeedbackByKey', ({feedbackKey}: { feedbackKey: string | undefined }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        return axios.get('/app/feedback/' + feedbackKey)
            .then(response => response.data)
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);

export const getFeedbackResults = createAsyncThunk(
    'feedback/getFeedbackResults', ({feedbackId, externalKey, jwtToken}: {
        feedbackId: string | undefined,
        externalKey: string | undefined,
        jwtToken: string | undefined
    }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };

        if (!externalKey) {
            externalKey = 'all';
        }
        return axios.get('/manage/feedback/' + feedbackId + '/' + externalKey + '/results', config)
            .then(response => response.data)
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);

export const deleteFeedbackResults = createAsyncThunk(
    'feedback/deleteFeedbackResults', ({feedbackId, externalKey, jwtToken}: {
        feedbackId: string | undefined,
        externalKey: string | undefined,
        jwtToken: string | undefined
    }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };

        if (!externalKey) {
            externalKey = 'all';
        }
        return axios.delete('/manage/feedback/' + feedbackId + '/' + externalKey + '/results', config)
            .then(response => response.data)
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);

export const getFeedbackResponses = createAsyncThunk(
    'feedback/getFeedbackResponses', ({feedbackId, externalKey, jwtToken}: {
        feedbackId: string | undefined,
        externalKey: string | undefined,
        jwtToken: string | undefined
    }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };

        if (!externalKey) {
            externalKey = 'all';
        }
        return axios.get('/manage/feedback/' + feedbackId + '/' + externalKey + '/responses', config)
            .then(response => response.data)
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);
export const getFeedbackResponsesAsExcel = createAsyncThunk(
    'feedback/getFeedbackResponsesAsExcel', ({feedbackId, externalKey, jwtToken}: {
        feedbackId: string | undefined,
        externalKey: string | undefined,
        jwtToken: string | undefined
    }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)},
            responseType: 'blob'
        } as AxiosRequestConfig;

        if (!externalKey) {
            externalKey = 'all';
        }
        return axios.get('/manage/feedback/' + feedbackId + '/' + externalKey + '/responses/excel', config)
            .then(response => {
                const href = URL.createObjectURL(response.data);

                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', '' + response.headers['content-disposition']?.replace('attachment; filename=', '')); //or any other extension
                document.body.appendChild(link);
                link.click();

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            })
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);

export const sendFeedback = createAsyncThunk(
    'feedback/sendFeedback', ({externalKey, answers}: {
        externalKey: string | undefined,
        answers: FeedbackAnswer[]
    }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        return axios.put('/app/feedback/' + externalKey, {answers: answers})
            .then(response => {
                return response.data;
            })
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);

export const saveFeedback = createAsyncThunk(
    'feedback/saveFeedback', ({feedbackForm, jwtToken}: {
        feedbackForm: FeedbackForm,
        jwtToken: string | undefined
    }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));

        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };
        return axios.post('/manage/feedback', feedbackForm, config)
            .then(response => {
                return response.data;
            })
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);

export const sendInvitations = createAsyncThunk(
    'feedback/sendInvitations', (params: {
        feedbackId: string,
        serviceExternalKey: string | undefined,
        feedbackInvitationType: string,
        jwtToken: string | undefined
    }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));

        const config = {
            headers: {'Authorization': bearerAuth(params.jwtToken)}
        };
        let url = '/manage/feedback/' + params.feedbackId;
        if (params.serviceExternalKey) {
            url = url + '/' + params.serviceExternalKey;
        }
        url = url + '/invite/' + params.feedbackInvitationType;
        return axios.post(url, {}, config)
            .then(response => {
                return response.data;
            })
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);
