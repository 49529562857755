import { createAsyncThunk } from '@reduxjs/toolkit';
import { setLoading } from '../global';
import axios from 'axios';
import { bearerAuth } from '../auth';
import { BackendSortParams, OrderEnum } from '../../../components';
import { SupportForm } from './SupportForm';


export const getSupportItems = createAsyncThunk(
    'supports/getSupportItems', (params: {
        jwtToken: string,
        parentId?: string,
        page: number,
        pageSize: number,
        sortParameters: BackendSortParams
    }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(params.jwtToken)}
        };
        var url = '/manage/support?page=' + params.page + '&size=' + params.pageSize + "&sort=" + params.sortParameters.field + "," + OrderEnum[params.sortParameters.order];
        if (params.parentId) {
            url = url + '&parentId=' + params.parentId;
        }

        return axios.get(url, config)
            .then(response => response.data)
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);

export const getSupport = createAsyncThunk(
    'supports/getSupport', ({jwtToken, supportId}: { jwtToken: string, supportId: string }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };

        return axios.get('/manage/support/' + supportId, config)
            .then(response => response.data)
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);


export const saveSupport = createAsyncThunk(
    'supports/saveSupport', ({jwtToken, supportForm}: {jwtToken: string, supportForm: SupportForm}, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };
        const body = {
            id: supportForm.id,
            parentId: supportForm.parentId,
            serviceExternalKey: supportForm.allServices ? null :supportForm.service?.externalKey,
            title: supportForm.title,
            body: supportForm.body,
            supportType: supportForm.supportType
        };
        return axios.post('/manage/support', body, config)
            .then(response => response.data)
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);

export const deleteSupport = createAsyncThunk(
    'supports/getSupport', ({jwtToken, supportId}: { jwtToken: string, supportId: number }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };

        return axios.delete('/manage/support/' + supportId, config)
            .then(response => response.data)
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);

