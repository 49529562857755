import React, {FC, ReactElement} from 'react';
import AuthBackground from "../../assets/images/auth/AuthBackground";
import {Box, Grid} from "@mui/material";
import AuthFooter from "../molecules/auth/AuthFooter";

interface Props {
    header: ReactElement;
    content: ReactElement;
}

export const HeaderFooterOnlyLayout: FC<Props> = ({header, content}) => {
    return (
        <Box sx={{minHeight: '100vh'}}>
            <AuthBackground/>
            <Grid
                container
                direction="column"
                sx={{
                    minHeight: '100vh',
                    flexDirection: 'column',
                    justifyContent: 'space-between'
                }}>
                <Grid item xs={12} sx={{ml: 3, mt: 3}}>
                    {header}
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{p: {xs: 2, sm: 3, md: 4, xl: 5}}}>{content}</Box>
                </Grid>
                <Grid item xs={12} sx={{m: 3, mt: 1}}>
                    <AuthFooter/>
                </Grid>
            </Grid>
        </Box>
    );
};
