import React, { FC } from 'react';

interface Props {
}


export const AppInit: FC<Props> = () => {
    // const dispatch = useAppDispatch();
    // const { keycloak } = useKeycloak();

    // useEffect(() => {
    //     if (keycloak) {
    //         dispatch(getUserExtrasMe(keycloak.token!));
    //         dispatch(getTenantConfig(keycloak.token!));
    //     }
    // }, []);
    return (<span/>);
};
