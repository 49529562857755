// Selectors
import { createSelector } from '@reduxjs/toolkit';
import { ApplicationState } from '../../store';


export const disruptionsSelector = (state: ApplicationState) => state.disruptionState
export const disruptionSelector = (state: ApplicationState) => state.disruptionState



export const disruptionsLoadingSelector = createSelector(disruptionsSelector, (state) => state.loading);
