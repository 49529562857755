import React, { FC } from 'react';
import { t } from 'i18next';
import { ImpactType } from '../../state';
import { ListItemText, MenuItem, Select } from '@mui/material';


interface Props {
    selectedImpactTypeId?: number|string;
    onSelect: (impactType: ImpactType) => void;
    impactTypes?: ImpactType[];
    disabled?: boolean
}


export const ImpactTypeSelectCombo: FC<Props> = (props) => {
    function onSelectItem(id: string|number) {
        props.onSelect(getSelectedImpactType(id));
    }

    function getSelectedImpactType(id: string|number):ImpactType {
        const selectedId = Number(id);
        return props.impactTypes!.filter((impactType) => impactType.id === selectedId)[0]  ;
    }

    function getImpactTypeLabel(selectedId: number|string):string {
        const impactType = getSelectedImpactType(selectedId);
        return impactType.description;
    }

    return (
        <Select value={props.selectedImpactTypeId ? props.selectedImpactTypeId : ''}
                displayEmpty
                onChange={e => onSelectItem(e.target.value)}
                disabled={props.disabled}
                renderValue={(selected) => {
                    if (!selected) {
                        return <ListItemText primary={t('fields.impactType.single.selectA')}/>;
                    }

                    return <ListItemText primary={getImpactTypeLabel(selected)}/>;
                }}>
            {props.impactTypes?.map((impactType) => (
                <MenuItem key={impactType.id} value={impactType.id}>
                    <ListItemText primary={impactType.description} />
                </MenuItem>))}
        </Select>
    )
};

ImpactTypeSelectCombo.defaultProps = {
    disabled: false
}
