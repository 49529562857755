import { createSlice } from '@reduxjs/toolkit';
import { Service } from '../services';
import { defaultGenericPagableState, GenericPageableState } from '../model';
import { addGenericPageableStateListBuilders, addGenericPageableStateSingleBuilders } from '../slice';
import { getSupport, getSupportItems } from './SupportApi';


export interface Support {
    id: number | undefined;
    parentId: number | undefined;
    service: Service;
    title: string;
    body: string;
    supportType: string;
    pages: Support[];
}

export interface SupportState extends GenericPageableState<Support> {
}

export const supportSlice = createSlice({
    name: 'supports',
    initialState: defaultGenericPagableState,
    reducers: {},
    extraReducers: builder => {
        addGenericPageableStateListBuilders(builder,getSupportItems);
        addGenericPageableStateSingleBuilders(builder, getSupport);
    },
});

