// third-party
import {FormattedMessage} from 'react-intl';

// assets
import {Danger, Flash, Star1} from 'iconsax-react';
import React from 'react';
import {NavItemType} from '../../../atoms/types/menu';

// type

// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //

const manage: NavItemType = {
  id: 'group-manage',
  title: <FormattedMessage id="menu.manage" />,
  type: 'group',
  children: [
    {
      id: 'feedback',
      title: <FormattedMessage id="fields.feedback.plural.typeDescription" />,
      url: '/manage/feedback/list',
      type: 'item',
      icon: Star1,
      breadcrumbs: true
    },
    {
      id: 'maintenance',
      title: <FormattedMessage id="menu.maintenance" />,
      url: '/manage/maintenance/list',
      type: 'item',
      icon: Danger,
      breadcrumbs: true
    },
    {
      id: 'disruptions',
      title: <FormattedMessage id="menu.disruptions" />,
      url: '/manage/disruptions/list',
      type: 'item',
      icon: Flash,
      breadcrumbs: true
    }
  ]
};

export default manage;
