import { createSlice } from '@reduxjs/toolkit';
import { getIssue, getIssues } from './IssuesApi';
import { Service } from '../services';
import { defaultGenericPagableState, GenericPageableState } from '../model';
import { addGenericPageableStateListBuilders, addGenericPageableStateSingleBuilders } from '../slice';


export interface Issue {
    id: number;
    service: Service;
    description: string;
    issueTypeDescription: string;
    impactTypeDescription: string;
    reportingSource: string;
    attachment: string;
    hasAttachment: boolean;
    relevantFrom: Date;
    estimatedRelevantUntil: Date | undefined;
    actualRelevantUntil: Date | undefined;
    reportedBy: string;
    reportedAt: Date;
}

export interface IssuesState extends GenericPageableState<Issue> {}

export const issueSlice = createSlice({
    name: 'issues',
    initialState: defaultGenericPagableState,
    reducers: {},
    extraReducers: builder => {
        addGenericPageableStateListBuilders(builder, getIssues);
        addGenericPageableStateSingleBuilders(builder, getIssue);
    },
});

