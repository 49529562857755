import { createSlice } from '@reduxjs/toolkit';
import { getUserExtrasMe } from "./AuthenticationApi";

export interface User {
    name: string;
    avatar: string;
    organisations: Organisation[];
}

export interface Organisation {
    id: number;
    name: string;
}

export interface AuthenticationState {
    loading: boolean;
    error: string | undefined;
    user: User | undefined;
    organisations: Organisation[];
}

const INITIAL_STATE: AuthenticationState = {
    loading: false,
    error: undefined,
    user: undefined,
    organisations: []
};

export const authenticationSlice = createSlice({
    name: 'authentication',
    initialState: INITIAL_STATE,
    reducers: {},
    extraReducers: {
        [getUserExtrasMe.pending.type]: (state: AuthenticationState, action) => {
            return {
                ...state,
                loading: true,
                error: undefined
            };
        },
        [getUserExtrasMe.fulfilled.type]: (state: AuthenticationState, action) => {
            return {
                ...state,
                loading: false,
                error: undefined,
                user: Object.assign([], action.payload),
                organisations: Object.assign([], action.payload.organisations),
            };
        },
        [getUserExtrasMe.rejected.type]: (state: AuthenticationState, action) => {
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        },
    }
});

