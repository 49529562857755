import React, { FC, PropsWithChildren } from 'react';
import styled from "styled-components";
import { useTranslation } from 'react-i18next';


interface LoadingProps {
    isLoading?: boolean;
    hasItems?: boolean;
    fullHeight?: boolean;

}

export const LoadingContainer: FC<LoadingProps & PropsWithChildren> = (props) => {
    const {t} = useTranslation();


    function getClassNames() {
        var classNames = "d-flex align-items-center justify-content-around justify-content-center";
        if (props.fullHeight) {
            classNames = 'h-100 ' + classNames;
        }
        return classNames;
    }

    function getLoadingPage() {
        return (
            <Container>
                <div className={getClassNames()}>
                    <span>
                        {t('generic.loading')}
                    </span>
                </div>
            </Container>
        )
    }

    if (props.isLoading && !props.hasItems) {
        return getLoadingPage();
    } else {
        return (<span>{props.children}</span>);
    }
};

const Container = styled.div`
  height: 50vh;
`;

LoadingContainer.defaultProps = {
    fullHeight: true,
    hasItems: false,
    isLoading: false
}
