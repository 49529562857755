// third-party
import {FormattedMessage} from 'react-intl';

// assets
import {KyberNetwork, Like, Location, MenuBoard, MessageQuestion, Ticket} from 'iconsax-react';

// type
import {NavItemType} from '../../../atoms/types/menu';
import React from 'react';

const referenceData: NavItemType = {
  id: 'group-reference-data',
  title: <FormattedMessage id="menu.referenceData" />,
  icon: KyberNetwork,
  type: 'group',
  children: [
    {
      id: 'locations',
      title: <FormattedMessage id="menu.locations" />,
      search: 'locations',
      type: 'item',
      url: '/manage/locations',
      icon: Location
    },
    {
      id: 'issuetypes',
      title: <FormattedMessage id="menu.issueTypes" />,
      search: 'issuetypes',
      type: 'item',
      url: '/manage/issuetypes',
      icon: Ticket
    },
    {
      id: 'impacttypes',
      title: <FormattedMessage id="menu.impactTypes" />,
      search: 'impacttypes',
      type: 'item',
      url: '/manage/impacttypes',
      icon: Like
    },
    {
      id: 'services',
      title: <FormattedMessage id="menu.services" />,
      search: 'services',
      type: 'item',
      url: '/manage/services',
      icon: MenuBoard
    },
    {
      id: 'serviceProfiles',
      title: <FormattedMessage id="menu.serviceProfiles" />,
      search: 'serviceProfile',
      type: 'item',
      url: '/manage/serviceprofiles',
      icon: Like
    },
    {
      id: 'support',
      title: <FormattedMessage id="menu.support" />,
      search: 'support',
      type: 'item',
      url: '/manage/support',
      icon: MessageQuestion
    },
  ]
};

export default referenceData;
