import { createSlice } from '@reduxjs/toolkit';
import { defaultGenericPagableState, GenericPageableState } from '../model';
import {addGenericPageableStateListBuilders, addGenericPageableStateSingleBuilders} from '../slice';
import {getImpactType, getImpactTypes, saveImpactType, saveImpactTypeDisplayOrder} from './ImpactTypeApi';


export interface ImpactType {
    id: number;
    description: string;
    logo: string;
    displayOrder: number;
}

export interface ImpactTypeState extends GenericPageableState<ImpactType> {
}

export const impactTypeSlice = createSlice({
    name: 'ImpactTypes',
    initialState: defaultGenericPagableState,
    reducers: {},
    extraReducers: builder => {
        addGenericPageableStateListBuilders(builder, getImpactTypes);
        addGenericPageableStateListBuilders(builder, saveImpactTypeDisplayOrder);
        addGenericPageableStateSingleBuilders(builder, getImpactType);
        addGenericPageableStateSingleBuilders(builder, saveImpactType);
    },
});

