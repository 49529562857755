import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';

import {Container} from "react-bootstrap";
import {Provider} from "react-redux";
import {store} from "./state";
import {AppInit} from "./components/AppInit";
import {configureAxiosDefaults} from './AxiosConfig';
import AuthenticationStateHandler from './components/organisms/AuthenticationStateHandler';
import {I18n} from './components/I18n';
import {
    AuthenticationExpiredContent,
    DisruptionsListContent,
    FeedbackDetailContent,
    FeedbackInviteUsersContent,
    FeedbackListContent,
    FeedbackRequestContent,
    FeedbackRequestSentContent,
    FeedbackResultContent,
    FeedbackResultListContent,
    ImpactTypeDetailContent,
    ImpactTypeListContent,
    IssueTypeDetailContent,
    IssueTypeListContent,
    LocationListContent,
    LoginAuthenticateContent,
    LoginPrepareContent,
    MaintenanceDetailContent,
    MaintenancesListContent,
    ServiceDetailContent,
    ServiceProfileDetailContent,
    ServiceProfilesListContent,
    ServicesListContent,
    SupportDetailContent,
    SupportListContent
} from "./components/organisms";
import ThemeCustomization from './themes';
import CommonLayout from './components/templates/CommonLayout';
import MainLayout from './components/templates/MainLayout';
import Locales from './components/molecules/Locales';
import {IssuesListContent} from './components/organisms/issues/IssuesListContent';
import {IssueDetailContent} from './components/organisms/issues/IssueDetailContent';
import {DisruptionDetailContent} from './components/organisms/disruptions/DisruptionDetailContent';
import {SignupFormContent} from "./components/organisms/signup/SignupFormContent";
import {SignupCheckEmailContent} from "./components/organisms/signup/SignupCheckEmailContent";
import {SignupCompletedContent} from "./components/organisms/signup/SignupCompleted";
import {SignupConfirmContent} from "./components/organisms/signup/SignupConfirm";
import {ExternalRedirect} from "./components/molecules/ExternalRedirect";
import {DashboardContent} from './components/organisms/dashboard';
import {SnackbarProvider} from 'notistack';
import {Loader} from "./components/molecules/Loader";


function App() {
    configureAxiosDefaults(store);
    return (
        <Provider store={store}>
            <AppInit/>
            <I18n/>
            <SnackbarProvider autoHideDuration={5000} />
            <BrowserRouter>
                <ThemeCustomization>
                    <Locales>
                <Container fluid className="px-0">
                    <Loader/>
                    <AuthenticationStateHandler>
                        <AuthenticationStateHandler.Loading>
                            <div>Loading</div>
                        </AuthenticationStateHandler.Loading>
                        <AuthenticationStateHandler.AuthenticationExpired>
                            <Routes>
                                <Route path="/" element={<CommonLayout layout={'header-footer-only'}/>} >
                                    <Route path="/" element={<AuthenticationExpiredContent/>}/>
                                    <Route path="*" element={<AuthenticationExpiredContent/>}/>
                                </Route>
                            </Routes>
                        </AuthenticationStateHandler.AuthenticationExpired>
                        <AuthenticationStateHandler.UnAuthenticated>
                            <Routes >
                                <Route path="/" element={<CommonLayout />}>
                                    <Route path="/" element={<LoginPrepareContent/>}/>
                                    <Route path="/register"  element={<ExternalRedirect url="https://www.satisfeed.nl/prijzen" /> }/>
                                    <Route path="authenticate" element={<LoginAuthenticateContent/>}/>
                                    <Route path="/signup" element={<CommonLayout layout={'header-footer-only'}/>}>
                                        <Route path="/signup/checkemail" element={<SignupCheckEmailContent/>}/>
                                        <Route path="/signup/confirm/:signupRequestExternalKey" element={<SignupConfirmContent/>}/>
                                        <Route path="/signup/completed" element={<SignupCompletedContent/>}/>
                                        <Route path="/signup/:subscriptionType" element={<SignupFormContent/>}/>
                                    </Route>
                                    <Route path="feedback/:tenantKey/:feedbackKey/sent" element={<FeedbackRequestSentContent/>}/>
                                    <Route path="feedback/:feedbackKey/sent" element={<FeedbackRequestSentContent/>}/>
                                    <Route path="feedback/:tenantKey/:feedbackKey" element={<FeedbackRequestContent/>}/>
                                    <Route path="feedback/:feedbackKey" element={<FeedbackRequestContent/>}/>
                                    <Route path="*" element={<LoginPrepareContent/>}/>
                                </Route>
                            </Routes>
                        </AuthenticationStateHandler.UnAuthenticated>
                        <AuthenticationStateHandler.Authenticated>
                            <Routes>
                                <Route path="/" element={<MainLayout />}>
                                    <Route path="/" element={<DashboardContent/>}/>
                                    <Route path="dashboard/default" element={<DashboardContent/>}/>
                                    <Route path="manage/services" element={<ServicesListContent/>}/>
                                    <Route path="manage/service/add" element={<ServiceDetailContent/>}/>
                                    <Route path="manage/service/:serviceExternalKey" element={<ServiceDetailContent/>}/>
                                    <Route path="manage/serviceprofiles" element={<ServiceProfilesListContent/>}/>
                                    <Route path="manage/serviceprofile/add" element={<ServiceProfileDetailContent/>}/>
                                    <Route path="manage/serviceprofile/:serviceProfileExternalKey" element={<ServiceProfileDetailContent/>}/>
                                    <Route path="manage/feedback/list" element={<FeedbackListContent/>}/>
                                    <Route path="manage/feedback/add" element={<FeedbackDetailContent/>}/>
                                    <Route path="manage/feedback/:feedbackId/responses" element={<FeedbackResultListContent/>}/>
                                    <Route path="manage/feedback/:feedbackId/results" element={<FeedbackResultContent/>}/>
                                    <Route path="manage/feedback/:feedbackId/invite" element={<FeedbackInviteUsersContent/>}/>
                                    <Route path="manage/feedback/:feedbackId/:serviceExternalKey/invite" element={<FeedbackInviteUsersContent/>}/>
                                    <Route path="manage/feedback/:feedbackId" element={<FeedbackDetailContent/>}/>

                                    <Route path="feedback/:tenantKey/:feedbackKey/sent" element={<FeedbackRequestSentContent/>}/>
                                    <Route path="feedback/:feedbackKey/sent" element={<FeedbackRequestSentContent/>}/>
                                    <Route path="feedback/:tenantKey/:feedbackKey" element={<FeedbackRequestContent/>}/>
                                    <Route path="feedback/:feedbackKey" element={<FeedbackRequestContent/>}/>

                                    <Route path="dashboard/issues/:serviceExternalKey/:reportedAtFrom/:reportedAtUntil" element={<IssuesListContent/>}/>
                                    <Route path="dashboard/issues/:serviceExternalKey/:reportedAtFrom" element={<IssuesListContent/>}/>
                                    <Route path="dashboard/issues" element={<IssuesListContent/>}/>
                                    <Route path="dashboard/issues/:issueId" element={<IssueDetailContent/>}/>
                                    <Route path="manage/disruptions/list" element={<DisruptionsListContent/>}/>
                                    <Route path="manage/disruption/add" element={<DisruptionDetailContent/>}/>
                                    <Route path="manage/disruption/:disruptionId" element={<DisruptionDetailContent/>}/>
                                    <Route path="manage/maintenance/list" element={<MaintenancesListContent/>}/>
                                    <Route path="manage/maintenance/add" element={<MaintenanceDetailContent/>}/>
                                    <Route path="manage/maintenance/:maintenanceId" element={<MaintenanceDetailContent/>}/>
                                    <Route path="manage/support" element={<SupportListContent/>}/>
                                    <Route path="manage/support/add/:parentId" element={<SupportDetailContent/>}/>
                                    <Route path="manage/support/add" element={<SupportDetailContent/>}/>
                                    <Route path="manage/support/:supportId" element={<SupportDetailContent/>}/>
                                    <Route path="manage/locations" element={<LocationListContent/>}/>
                                    <Route path="manage/impacttypes" element={<ImpactTypeListContent/>}/>
                                    <Route path="manage/impacttypes/add" element={<ImpactTypeDetailContent/>}/>
                                    <Route path="manage/impacttypes/:impactTypeId" element={<ImpactTypeDetailContent/>}/>
                                    <Route path="manage/issuetypes" element={<IssueTypeListContent/>}/>
                                    <Route path="manage/issuetypes/add" element={<IssueTypeDetailContent/>}/>
                                    <Route path="manage/issuetypes/:issueTypeId" element={<IssueTypeDetailContent/>}/>
                                    <Route path="*" element={<DashboardContent/>}/>
                                </Route>
                            </Routes>
                        </AuthenticationStateHandler.Authenticated>
                    </AuthenticationStateHandler>
                </Container>
                    </Locales>
                </ThemeCustomization>
            </BrowserRouter>
       </Provider>
    );
}

export default App;
