import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { addLocation, deleteLocation, getLocations, updateLocation } from './LocationApi';


export interface Location {
    id?: number;
    name: string;
}

export interface LocationState {
    loadExecuted: boolean;
    loading: boolean;
    error: string | undefined;
    locations: Location[];
}

const INITIAL_STATE: LocationState = {
    loadExecuted: false,
    loading: false,
    error: undefined,
    locations: [],
};

export const locationSlice = createSlice({
    name: 'location',
    initialState: INITIAL_STATE,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getLocations.pending.type, (state: LocationState, action) => {
            return {
                ...state,
                loading: true,
                error: undefined
            };
        });
        builder.addCase(getLocations.fulfilled.type, (state: LocationState, action: PayloadAction) => {
            return {
                ...state,
                loadExecuted: true,
                loading: false,
                error: undefined,
                locations: Object.assign([], action.payload),
            };
        });
        builder.addCase(getLocations.rejected.type, (state: LocationState, action: any) => {
            return {
                ...state,
                loadExecuted: true,
                loading: false,
                error: action.error.message,
            };
        });
        builder.addCase(updateLocation.pending.type, (state: LocationState, action) => {
            return {
                ...state,
                loading: true,
                error: undefined
            };
        });
        builder.addCase(updateLocation.fulfilled.type, (state: LocationState, action: PayloadAction) => {
            return {
                ...state,
                loadExecuted: true,
                loading: false,
                error: undefined
            };
        });
        builder.addCase(updateLocation.rejected.type, (state: LocationState, action: any) => {
            return {
                ...state,
                loadExecuted: true,
                loading: false,
                error: action.error.message,
            };
        });
        builder.addCase(addLocation.pending.type, (state: LocationState, action) => {
            return {
                ...state,
                loading: true,
                error: undefined
            };
        });
        builder.addCase(addLocation.fulfilled.type, (state: LocationState, action: PayloadAction<Location>) => {
            let locations = Object.assign([], state.locations);
            locations.push(action.payload);
            return {
                ...state,
                loadExecuted: true,
                loading: false,
                error: undefined,
                locations: locations,
            };
        });
        builder.addCase(addLocation.rejected.type, (state: LocationState, action: any) => {
            return {
                ...state,
                loadExecuted: true,
                loading: false,
                error: action.error.message,
            };
        });
        builder.addCase(deleteLocation.pending.type, (state: LocationState, action) => {
            return {
                ...state,
                loading: true,
                error: undefined
            };
        });
        builder.addCase(deleteLocation.fulfilled.type, (state: LocationState, action: PayloadAction) => {
            return {
                ...state,
                loadExecuted: true,
                loading: false,
                error: undefined,
            };
        });
        builder.addCase(deleteLocation.rejected.type, (state: LocationState, action: any) => {
            return {
                ...state,
                loadExecuted: true,
                loading: false,
                error: action.error.message,
            };
        });
    },
});

