import { FeedbackQuestion, ServiceFeedbackRequest } from './FeedbackSlice';

export class FeedbackForm {
    id?: number;
    title: string = '';
    description?: string;
    thankYouDescription?: string;
    startDate?: Date;
    endDate?: Date;
    image?: string;
    services?: ServiceFeedbackRequest[];
    questions?: FeedbackQuestion[];

    static isValid(feedbackForm: FeedbackForm): boolean {
        function containsValue(value: any) {
            return value !== undefined && value !== null && (value.length === undefined || value.length > 0);
        }

        if (!containsValue(feedbackForm.title)) {
            return false;
        }

        if (!containsValue(feedbackForm.description)) {
            return false;
        }

        if (!containsValue(feedbackForm.image)) {
            return false;
        }

        if (!feedbackForm.services || feedbackForm.services.length === 0) {
            return false;
        }

        if (!feedbackForm.questions || feedbackForm.questions.length === 0) {
            return false;
        }

        return true;
    }
}
