
export interface AppTheme {
  generic: {
    fontFamily: string;
  },
  colors: {
    text: string;
    border: string;
    notification: string;
    headline1: string;
    headline2: string;
    headline3: string;
    headline4: string;
    headline5: string;
    headline6: string;
    primary: string;
    tint1: string;
    tint2: string;
  },
  button: {
    primaryButtonColor: string;
    primaryButtonHoverColor: string;
    primaryButtonTextColor: string;
  },
  header: {
    colorHeaderPrimary: string;
    colorHeaderSecondary: string;
    colorHeaderText: string;
  },
  stars: {
    ratedColor: string,
    hoverColor: string
  },
}

export const getAppTheme = (): AppTheme => {
  return {
    generic : {
      fontFamily: 'sans-serif',
    },
    colors: {
      text: 'rgb(28, 28, 30)',
      border: 'rgb(199, 199, 204)',
      notification: 'rgb(255, 69, 58)',
      headline1: 'rgb(28, 28, 30)',
      headline2: 'rgb(28, 28, 30)',
      headline3: 'rgb(28, 28, 30)',
      headline4: 'rgb(28, 28, 30)',
      headline5: 'rgb(28, 28, 30)',
      headline6: 'rgb(145,145,145)',
      primary: '#ffb612',
      tint1: '#ffe9b8',
      tint2: '#fff4db'
    },
    button:{
      primaryButtonColor: '#E8BB55',
      primaryButtonTextColor: 'rgb(28, 28, 30)',
      primaryButtonHoverColor: '#af8f3b'
    },
    header: {
      colorHeaderPrimary: '#E8BB55',
      colorHeaderSecondary: 'rgb(28, 28, 30)',
      colorHeaderText: 'rgb(28, 28, 30)',
    },
    forms: {
      labelColor: '#014770',
    },
    stars: {
      ratedColor: '#EFBA5E',
      hoverColor: '#EFBA5E'

    },
  } as AppTheme;
};
