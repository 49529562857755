import {createAsyncThunk} from '@reduxjs/toolkit';
import {setLoading} from '../global';
import axios from 'axios';
import {bearerAuth} from '../auth';
import {BackendSortParams, OrderEnum} from '../../../components';
import {ImpactTypeForm} from "./ImpactTypeForm";

export const getImpactTypes = createAsyncThunk(
    'ImpactTypes/getImpactTypes', (params: {
        jwtToken: string,
        page: number,
        pageSize: number,
        sortParameters: BackendSortParams
    }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(params.jwtToken)}
        };
        var url = '/manage/impactTypes?page=' + params.page + '&size=' + params.pageSize + "&sort=" + params.sortParameters.field + "," + OrderEnum[params.sortParameters.order];

        return axios.get(url, config)
            .then(response => response.data)
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);

export const saveImpactType = createAsyncThunk(
    'ImpactTypes/saveImpactType', (params: {
        jwtToken: string,
        impactTypeForm: ImpactTypeForm
    }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(params.jwtToken)}
        };

        return axios.post('/manage/impactTypes', params.impactTypeForm, config)
            .then(response => response.data)
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);

export const getImpactType = createAsyncThunk(
    'ImpactTypes/getImpactType', (params: {
        jwtToken: string,
        impactTypeId: number
    }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(params.jwtToken)}
        };
        var url = '/manage/impactTypes/' + params.impactTypeId;

        return axios.get(url, config)
            .then(response => response.data)
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);

export const saveImpactTypeDisplayOrder = createAsyncThunk(
    'ImpactTypes/saveImpactTypeDisplayOrder', (params: {
        jwtToken: string,
        impactTypeId: number,
        newDisplayOrder: number,
        page: number,
        pageSize: number,
        sortParameters: BackendSortParams
    }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(params.jwtToken)}
        };
        var url = '/manage/impactTypes/' + params.impactTypeId + '/' + params.newDisplayOrder + '?page=' + params.page + '&size=' + params.pageSize + "&sort=" + params.sortParameters.field + "," + OrderEnum[params.sortParameters.order];
        return axios.post(url, {}, config)
            .then(response => response.data)
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);

