export function formatDateForTransport(date: Date | undefined) {
    if ((date === undefined) || (date === null)) {
        return "";
    }
    let month = ('00' + (date.getMonth() + 1)).slice(-2);
    let day = ('00' + date.getDate()).slice(-2);
    let year = date.getFullYear();

    return [year, month, day].join('-');
}

export function formatDateTimeForTransport(date: Date | undefined) {
    if ((date === undefined) || (date === null)) {
        return "";
    }
    let month = ('00' + (date.getMonth() + 1)).slice(-2);
    let day = ('00' + date.getDate()).slice(-2);
    let year = date.getFullYear();
    let hours = ("00" + date.getHours()).slice(-2);
    let minutes = ("00" + date.getMinutes()).slice(-2);
    let seconds = ("00" + date.getSeconds()).slice(-2);

    return year + '-' + month + '-' + day + 'T' + hours + ":" + minutes + ":" + seconds;
}

export function formatDateTimeForDisplay(date: Date | undefined) {
    if ((date === undefined) || (date === null)) {
        return "";
    }
    if (!(date instanceof Date)) {
        date = new Date(date);
    }
    let month = '' + (date.getMonth() + 1)
    let day = '' + date.getDate()
    let year = date.getFullYear();
    let hours = ("00" + date.getHours()).slice(-2);
    let minutes = ("00" + date.getMinutes()).slice(-2);

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;
    return day + '-' + month + '-' + year + ' ' + hours + ":" + minutes;
}


export function toNullableDate(date: Date | string | undefined) {
    if (date) {
        return new Date(date);
    }
    return undefined;
}
