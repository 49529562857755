import { createSlice } from '@reduxjs/toolkit';
import { Service } from '../services';
import { defaultGenericPagableState, GenericPageableState } from '../model';
import { getMaintenance, getMaintenances } from './MaintenanceApi';
import { addGenericPageableStateListBuilders, addGenericPageableStateSingleBuilders } from '../slice';


export interface Maintenance {
    id: number | undefined;
    service: Service;
    title: string;
    description: string;
    plannedFrom: Date | undefined;
    plannedUntil: Date | undefined;
    reportedBy: string | undefined;
    reportedAt: Date | undefined;
    updatedBy: string | undefined;
    updatedAt: Date | undefined;

}

export interface MaintenanceState extends GenericPageableState<Maintenance> {
}

export const maintenanceSlice = createSlice({
    name: 'maintenances',
    initialState: defaultGenericPagableState,
    reducers: {},
    extraReducers: builder => {
        addGenericPageableStateListBuilders(builder,getMaintenances);
        addGenericPageableStateSingleBuilders(builder, getMaintenance);
    },
});

