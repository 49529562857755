import React, { FC, useState } from 'react';
import { MRT_ColumnDef } from 'material-react-table';
import { Location } from '../../../state';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material';
import { PrimaryButton } from '../../atoms/PrimaryButton';

export const CreateNewLocationModalPanel: FC<{
    columns: MRT_ColumnDef<Location>[];
    onClose: () => void;
    onSubmit: (values: Location) => void;
    open: boolean;
}> = ({ open, columns, onClose, onSubmit }) => {
    const [values, setValues] = useState<any>(() =>
        columns.reduce((acc, column) => {
            acc[column.accessorKey ?? ''] = '';
            return acc;
        }, {} as any),
    );

    const handleSubmit = () => {
        //put your validation logic here
        onSubmit(values);
        onClose();
    };

    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">Aanmaken nieuwe locatie</DialogTitle>
            <DialogContent>
                <br/>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: { xs: '300px', sm: '360px', md: '400px' },
                            gap: '1.5rem',
                        }}
                    >
                        {columns.filter(value => !(value.enableEditing === false)).map((column) => (
                            <TextField
                                key={column.accessorKey}
                                label={column.header}
                                name={column.accessorKey}
                                onChange={(e) =>
                                    setValues({ ...values, [e.target.name]: e.target.value })
                                }
                            />
                        ))}
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{ p: '1.25rem' }}>
                <Button onClick={onClose}>Sluiten</Button>
                <PrimaryButton onClick={handleSubmit} >Aanmaken</PrimaryButton>
            </DialogActions>
        </Dialog>
    );
};
