import React, {FC} from 'react';
import {Navbar, NavDropdown, Spinner} from "react-bootstrap";
import {Link, useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import {LinkContainer} from 'react-router-bootstrap'
import {getAppTheme} from '../AppTheme';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, authSelector, globalStateLoadingSelector, logout} from '../../state';
import {WhenAuthenticated} from '../molecules';
import {t} from 'i18next';

interface Props {
    menuItems: MenuItem[];
    hideLogin: boolean;
}

export class MenuItem {
    labelKey: string;
    href?: string;
    subMenuItems?: MenuItem[];

    constructor(labelKey: string, href?: string, subMenuItems?: MenuItem[]) {
        this.labelKey = labelKey;
        this.href = href;
        this.subMenuItems = subMenuItems;
    }
}

export const NavigationBar: FC<Props> = (props) => {
    let dispatch = useDispatch<AppDispatch>();
    let authState = useSelector(authSelector);
    // const { keycloak } = useKeycloak()
    // const handleLogInOut = () => {
    //     if (keycloak.authenticated) {
    //         keycloak.logout()
    //     } else {
    //         keycloak.login()
    //     }
    // }
    // const getUsername = () => {
    //     return keycloak.authenticated && keycloak.tokenParsed && (keycloak.tokenParsed?.given_name?.length > 0 ? keycloak.tokenParsed?.given_name : keycloak.tokenParsed?.family_name);
    // }
    //
    // const getLogInOutText = () => {
    //     return keycloak.authenticated ? t('menuitem.logoff') : t('menuitem.logon')
    // }

    let navigate = useNavigate();
    const loadingSelector = useSelector(globalStateLoadingSelector);
    let spinner;
    if (loadingSelector) {
        spinner = <Spinner animation="border" variant="light"/>;
    }

    function handleLogOut() {
        dispatch(logout({})).then(() => navigate('/'));
    }

    function createMenuItem(index: number, menuItem: MenuItem) {
        if (menuItem.href !== undefined) {
            return (
                <Navbar.Brand key={index}>
                    <Link key={index} className="navbar-brand"
                          to={menuItem.href!}><StyledText>{t(menuItem.labelKey)}</StyledText></Link>
                </Navbar.Brand>
            )
        } else if (menuItem.subMenuItems !== undefined) {
            return (
                <Navbar.Collapse key="colsub{index + menuItem.labelKey}">
                    <StyledNavDropdown className="navbar-brand" title={t(menuItem.labelKey)}
                                       key="sub{index + menuItem.labelKey}">
                        {menuItem.subMenuItems.map((menuItem, index2) => createSubMenuItem(index * 100 + index2, menuItem))}
                    </StyledNavDropdown>
                </Navbar.Collapse>
            )
        }
    }

    function createSubMenuItem(index: number, menuItem: MenuItem) {
        if (menuItem.href !== undefined) {
            return (
                <NavDropdown.Item key={index}>
                    <LinkContainer to={menuItem.href!}>
                        <StyledText>{t(menuItem.labelKey)}</StyledText>
                    </LinkContainer>
                </NavDropdown.Item>
            )
        }
    }
    return (
        <div>
            <StyledNavbar>
                {props.menuItems.map((menuItem, index) => createMenuItem(index, menuItem))}
                <Navbar.Collapse className="justify-content-end mr-4">
                    <WhenAuthenticated>
                        <StyledNavDropdown title={authState.emailAddress} id="userMenu">
                            <StyledNavDropdownItem onClick={handleLogOut}>Afmelden</StyledNavDropdownItem>
                        </StyledNavDropdown>
                    </WhenAuthenticated>

                    {spinner}
                </Navbar.Collapse>
            </StyledNavbar>
        </div>
    )
        ;
};

const StyledNavbar = styled(Navbar)`
  background-color: ${getAppTheme().header.colorHeaderPrimary};
  border-bottom: 10px solid ${getAppTheme().header.colorHeaderSecondary};
  padding-left: 10px;
  padding-right: 10px;
`;


const StyledNavDropdown = styled(NavDropdown)`
  color: ${getAppTheme().header.colorHeaderText};

  .nav-link {
    color: ${getAppTheme().header.colorHeaderText};
  }
`;

const StyledNavDropdownItem = styled(NavDropdown.Item)`
  color: ${getAppTheme().header.colorHeaderText};

  .nav-link {
    color: ${getAppTheme().header.colorHeaderText};
  }
`;

const StyledText = styled.span`
  color: ${getAppTheme().header.colorHeaderText};
`;
