import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { deleteFeedbackResults, getFeedback, getFeedbackByKey, getFeedbackRequests, getFeedbackResponses, getFeedbackResponsesAsExcel, getFeedbackResults, saveFeedback, sendFeedback, sendInvitations } from './FeedbackApi';
import { Service } from '../services';

export interface FeedbackRequest {
    id: number;
    tenantKey: string;
    service?: Service;
    image: string;
    imageContentType: string;
    title: string;
    startDate?: Date;
    endDate?: Date;
    description: string;
    questions?: FeedbackQuestion[];
    services?: ServiceFeedbackRequest[];
}

export interface FeedbackQuestion {
    id?: number;
    title?: string;
    description?: string;
    questionType?: string;
    possibleAnswers?: FeedbackPossibleAnswer[];
    enableRemarks?: boolean;
}

export interface FeedbackPossibleAnswer {
    answer: number;
    label: string;
}

export interface FeedbackResult {
    questionId: number;
    name: string;
    count: number;
}

export interface FeedbackResponse {
    id: number;
    answers: FeedbackAnswer[];
}

export interface FeedbackAnswer {
    questionId: number;
    rating: number;
    text: string;
}

export interface ServiceFeedbackRequest {
    externalKey: string;
    service: Service;
}

export interface FeedbackInvitationResponse {
    numberOfUsersInvited: number;
}

export interface FeedbackState {
    loadExecuted: boolean;
    loading: boolean;
    error: string | undefined;
    feedbacks: FeedbackRequest[];
    feedback: FeedbackRequest | undefined;
    feedbackResults: FeedbackResult[];
    feedbackResponses: FeedbackResponse[];
    feedbackInvitationResponse: FeedbackInvitationResponse | undefined;
}

const INITIAL_STATE: FeedbackState = {
    loadExecuted: false,
    loading: false,
    error: undefined,
    feedbacks: [],
    feedback: undefined,
    feedbackResults: [],
    feedbackResponses: [],
    feedbackInvitationResponse: undefined
};

export const feedbackSlice = createSlice({
    name: 'feedback',
    initialState: INITIAL_STATE,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getFeedbackRequests.pending.type, (state: FeedbackState, action) => {
            return {
                ...state,
                loading: true,
                error: undefined
            };
        });
        builder.addCase(getFeedbackRequests.fulfilled.type, (state: FeedbackState, action: PayloadAction) => {
            return {
                ...state,
                loadExecuted: true,
                loading: false,
                error: undefined,
                feedbacks: Object.assign([], action.payload),
            };
        });
        builder.addCase(getFeedbackRequests.rejected.type, (state: FeedbackState, action: any) => {
            return {
                ...state,
                loadExecuted: true,
                loading: false,
                error: action.error.message,
            };
        });
        builder.addCase(getFeedback.pending.type, (state: FeedbackState, action) => {
            return {
                ...state,
                loading: true,
                error: undefined
            };
        });
        builder.addCase(getFeedback.fulfilled.type, (state: FeedbackState, action: PayloadAction<FeedbackRequest>) => {
            return {
                ...state,
                loadExecuted: true,
                loading: false,
                error: undefined,
                feedback: Object.assign({}, action.payload),
            };
        });
        builder.addCase(getFeedback.rejected.type, (state: FeedbackState, action: any) => {
            return {
                ...state,
                loadExecuted: true,
                loading: false,
                error: action.error.message,
            };
        });
        builder.addCase(getFeedbackByKey.pending.type, (state: FeedbackState, action) => {
            return {
                ...state,
                loading: true,
                error: undefined
            };
        });
        builder.addCase(getFeedbackByKey.fulfilled.type, (state: FeedbackState, action: PayloadAction<FeedbackRequest>) => {
            return {
                ...state,
                loadExecuted: true,
                loading: false,
                error: undefined,
                feedback: Object.assign({}, action.payload),
            };
        });
        builder.addCase(getFeedbackByKey.rejected.type, (state: FeedbackState, action: any) => {
            return {
                ...state,
                loadExecuted: true,
                loading: false,
                error: action.error.message,
            };
        });
        builder.addCase(getFeedbackResults.pending.type, (state: FeedbackState, action) => {
            return {
                ...state,
                loading: true,
                error: undefined
            };
        });
        builder.addCase(getFeedbackResults.fulfilled.type, (state: FeedbackState, action: PayloadAction) => {
            return {
                ...state,
                loadExecuted: true,
                loading: false,
                error: undefined,
                feedbackResults: Object.assign([], action.payload),
            };
        });
        builder.addCase(getFeedbackResults.rejected.type, (state: FeedbackState, action: any) => {
            return {
                ...state,
                loadExecuted: true,
                loading: false,
                error: action.error.message,
            };
        });
        builder.addCase(deleteFeedbackResults.pending.type, (state: FeedbackState, action) => {
            return {
                ...state,
                loading: true,
                error: undefined
            };
        });
        builder.addCase(deleteFeedbackResults.fulfilled.type, (state: FeedbackState, action: PayloadAction) => {
            return {
                ...state,
                loadExecuted: true,
                loading: false,
                error: undefined,
                feedbackResults: Object.assign([], action.payload),
            };
        });
        builder.addCase(deleteFeedbackResults.rejected.type, (state: FeedbackState, action: any) => {
            return {
                ...state,
                loadExecuted: true,
                loading: false,
                error: action.error.message,
            };
        });
        builder.addCase(getFeedbackResponses.pending.type, (state: FeedbackState, action) => {
            return {
                ...state,
                loading: true,
                error: undefined
            };
        });
        builder.addCase(getFeedbackResponses.fulfilled.type, (state: FeedbackState, action: PayloadAction) => {
            return {
                ...state,
                loadExecuted: true,
                loading: false,
                error: undefined,
                feedbackResponses: Object.assign([], action.payload),
            };
        });
        builder.addCase(getFeedbackResponses.rejected.type, (state: FeedbackState, action: any) => {
            return {
                ...state,
                loadExecuted: true,
                loading: false,
                error: action.error.message,
            };
        });
        builder.addCase(getFeedbackResponsesAsExcel.pending.type, (state: FeedbackState, action) => {
            return {
                ...state,
                loading: true,
                error: undefined
            };
        });
        builder.addCase(getFeedbackResponsesAsExcel.fulfilled.type, (state: FeedbackState, action: PayloadAction) => {
            return {
                ...state,
                loadExecuted: true,
                loading: false,
                error: undefined,
            };
        });
        builder.addCase(getFeedbackResponsesAsExcel.rejected.type, (state: FeedbackState, action: any) => {
            return {
                ...state,
                loadExecuted: true,
                loading: false,
                error: action.error.message,
            };
        });
        builder.addCase(sendFeedback.pending.type, (state: FeedbackState, action) => {
            return {
                ...state,
                loading: true,
                error: undefined,
            };
        });
        builder.addCase(sendFeedback.fulfilled.type, (state: FeedbackState, action: PayloadAction<FeedbackRequest>) => {
            return {
                ...state,
                loadExecuted: true,
                loading: false,
                error: undefined
            };
        });
        builder.addCase(sendFeedback.rejected.type, (state: FeedbackState, action: any) => {
            return {
                ...state,
                loadExecuted: true,
                loading: false,
                error: action.error.message,
            };
        });
        builder.addCase(saveFeedback.pending.type, (state: FeedbackState, action) => {
            return {
                ...state,
                loading: true,
                error: undefined,
            };
        });
        builder.addCase(saveFeedback.fulfilled.type, (state: FeedbackState, action: PayloadAction<FeedbackRequest>) => {
            return {
                ...state,
                loadExecuted: true,
                loading: false,
                error: undefined
            };
        });
        builder.addCase(saveFeedback.rejected.type, (state: FeedbackState, action: any) => {
            return {
                ...state,
                loadExecuted: true,
                loading: false,
                error: action.error.message,
            };
        });
        builder.addCase(sendInvitations.pending.type, (state: FeedbackState, action) => {
            return {
                ...state,
                loading: true,
                error: undefined
            };
        });
        builder.addCase(sendInvitations.fulfilled.type, (state: FeedbackState, action: PayloadAction<FeedbackInvitationResponse>) => {
            return {
                ...state,
                loadExecuted: true,
                loading: false,
                error: undefined,
                feedbackInvitationResponse: Object.assign([], action.payload),
            };
        });
        builder.addCase(sendInvitations.rejected.type, (state: FeedbackState, action: any) => {
            return {
                ...state,
                loadExecuted: true,
                loading: false,
                error: action.error.message,
            };
        });

    },
});

