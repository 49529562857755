import { SortingState } from '@tanstack/react-table';

export interface BackendSortParams {
    field: string;
    order: OrderEnum;
}

export enum OrderEnum {
    asc, desc
}

export const getSortParameters = (sortingState: SortingState | undefined, defaultOrder: BackendSortParams): BackendSortParams => {
    if ((sortingState !== undefined) && sortingState.length > 0) {
        return {field: sortingState[0].id, order: sortingState[0].desc ? OrderEnum.desc : OrderEnum.asc};
    } else {
        return defaultOrder;
    }
}
