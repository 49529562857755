import React, {ReactNode} from 'react';
import styled from 'styled-components';
import {TITLE_COLOR} from '../../colors';

interface Props {
  color?: string;
  truncate?: boolean;
  children?: ReactNode;
}

export const SubTitleText: React.FC<Props> = ({ color = TITLE_COLOR, truncate = true, children }) => {

  return <Text color={color}>{children}</Text>;
};

const Text = styled.h1<Pick<Props, 'color'>>`
  font-size: 24px;
  font-weight: bold;
`;
