import {createSlice} from '@reduxjs/toolkit';
import {getService, getServices, saveService} from './ServicesApi';
import {defaultGenericPagableState, GenericPageableState} from '../model';
import {addGenericPageableStateListBuilders, addGenericPageableStateSingleBuilders} from '../slice';
import {ImpactType} from '../impacttypes';
import {IssueType} from '../issuetypes';
import {ServiceProfile} from '../serviceprofiles';


export interface Service {
    externalKey: string;
    name: string;
    locationId: number;
    location: string;
    reportingBucketSize?: number;
    reportingBucketPeriodInMinutes?: number;
    reportingBucketMarginInMinutes?: number;
    base64EncodedLogo: string;
    administratorEmailAddresses: string[];
    additionalUserEmailAddresses: string[];
    allowedIssueTypes: IssueType[];
    allowedImpactTypes: ImpactType[];
    serviceProfiles: ServiceProfile[];
    showInApp: boolean;
}

export interface ServicesState extends GenericPageableState<Service> {}

export const servicesSlice = createSlice({
    name: 'services',
    initialState: defaultGenericPagableState,
    reducers: {},
    extraReducers: builder => {
        addGenericPageableStateListBuilders(builder,getServices);
        addGenericPageableStateSingleBuilders(builder, getService);
        addGenericPageableStateSingleBuilders(builder, saveService);
    },
});

