import {createAsyncThunk} from '@reduxjs/toolkit';
import {setLoading} from '../global';
import axios from 'axios';
import {bearerAuth} from '../auth';
import {BackendSortParams, OrderEnum} from '../../../components';
import {formatDateTimeForTransport} from '../DateUtils';
import {DisruptionForm} from './DisruptionForm';

export const getDisruptions = createAsyncThunk(
    'disruptions/getDisruptions', ({jwtToken, globalSearchText, page, pageSize, sortParameters}: {jwtToken: string, globalSearchText: string, page: number, pageSize: number, sortParameters: BackendSortParams}, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };
        var url = '/manage/disruptions?page=' + page + '&size=' + pageSize + "&sort=" + sortParameters.field + "," + OrderEnum[sortParameters.order];
        if (globalSearchText && globalSearchText.length > 0) {
            url = url + "&globalSearchText=" + encodeURI(globalSearchText);
        }

        return axios.get(url, config)
            .then(response => response.data)
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);

export const getDisruption = createAsyncThunk(
    'disruptions/getDisruption', ({jwtToken, disruptionId}: {jwtToken: string, disruptionId: string}, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };

        return axios.get('/manage/disruptions/' + disruptionId, config)
            .then(response => response.data)
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);

export const saveDisruption = createAsyncThunk(
    'disruptions/saveDisruption', ({jwtToken, disruptionForm}: {jwtToken: string, disruptionForm: DisruptionForm}, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };
        const body = {
            id: disruptionForm.id,
            serviceExternalKey: disruptionForm.service?.externalKey,
            description: disruptionForm.description,
            startedAt: formatDateTimeForTransport(disruptionForm.startedAt),
            endedAt: formatDateTimeForTransport(disruptionForm.endedAt),
            dominantIssueTypeId: disruptionForm.dominantIssueTypeId,
            dominantImpactTypeId: disruptionForm.dominantImpactTypeId,
            notifyUsers: disruptionForm.notifyUsers
        };

        return axios.post('/manage/disruptions', body, config)
            .then(response => response.data)
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);
